export const INFO_LEVEL = 2;
export const WARNING_LEVEL = 1;
export const CRITICAL_LEVEL = 0;

export const LEVELS = [CRITICAL_LEVEL, WARNING_LEVEL, INFO_LEVEL];

export const LEVELS_TYPE = {
  CRITICAL_LEVEL: 'Critical',
  WARNING_LEVEL: 'Warning',
  INFO_LEVEL: 'Info'
};
