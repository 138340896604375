import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { SideNav as SpacerSideNav } from 'spacer-ui-kit';

import { ReactComponent as Stack } from 'icons/stack-white.svg';

const SideNav = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const hiddenClass = 'hidden text-opacity-100 whitespace-nowrap overflow-hidden';
  const blockClass = 'block overflow-auto whitespace-normal text-opacity-100';
  const [visibleClass, setVisibleClass] = useState(blockClass);
  const [labelClass, setLabelClass] = useState(blockClass);
  const location = window.location.href.split('/')[3];
  const [selected, setSelected] = useState(location);

  const collapseSideNav = () => {
    setVisibleClass(visibleClass ? '' : blockClass);
    setLabelClass(labelClass === blockClass ? hiddenClass : blockClass);
  };

  const handleRouteClick = route => {
    history.push(`/${route}`);
    setSelected(route);
  };

  return (
    <SpacerSideNav onCollapse={() => collapseSideNav()}>
      <div
        onClick={() => handleRouteClick('logs')}
        className={`${visibleClass} ${selected === 'logs' ? 'bg-opacity-40' : 'bg-opacity-10'}
        bg-dark hover:bg-opacity-20 rounded-xl mx-3.5 text-white flex flex-row mb-2.5 cursor-pointer items-center`}
      >
        <div className="text-xl mr-2 p-2 overflow-hidden">
          <Stack className="w-5 h-5" />
        </div>
        <div className={`${labelClass} animate-fade-in`}>{t('logs_side_button_logs')}</div>
      </div>
      {/* // TODO: Uncomment this after we have Reports & Files pages
      <div className={`${showMenuText && 'ml-16'} text-white flex flex-row  text-center items-center content-end my-4 cursor-pointer`}>
        <div className="text-xl mr-2">
          <FontAwesomeIcon icon={faChartLine}/>
        </div>
        <div className='item-label'>Logs</div>
      </div>

      <div className={`${visibleText} menu-container ml-5 text-white flex flex-row my-4 cursor-pointer`}>
        <div className="text-xl mr-2">
          <FontAwesomeIcon icon={faPaste}/>
        </div>
        <div className='item-label'>Files</div>
      </div>
        <div className={`${showMenuText && 'opacity-0 transition duration-500'} transition duration-500`}>Files</div>
      </div> */}
    </SpacerSideNav>
  );
}

export default SideNav;
