import axios from 'axios';
import Agent from 'agentkeepalive';

const httpAgent = new Agent({
  maxSockets: 100,
  maxFreeSockets: 10,
  timeout: 60000,
  freeSocketTimeout: 30000,
  keepAlive: true,
  withCredentials: true,
  credentials: 'include'
});

axios.defaults.withCredentials = true;

const instance = axios.create({ httpAgent });

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      window.location.replace(`${process.env.REACT_APP_SSO_LOGIN_URL}?sso=${window.location.href}`);

      return;
    }

    return Promise.reject({ status: error.response.status, data: error.response.data });
  }
);

export default instance;
