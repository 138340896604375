import { lazy } from 'react';

import { PERMISSIONS } from 'constants/security';

const Logs = lazy(() => import('pages/logs'));
const Forbidden = lazy(() => import('pages/errors/forbidden'));

export const routes = [{
  path: '/forbidden',
  exact: true,
  component: Forbidden,
  isPublic: true
}, {
  path: '/logs',
  exact: true,
  component: Logs,
  permissions: [PERMISSIONS.VIEW_LOG_LIST]
}];
