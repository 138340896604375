export const PERMISSIONS = {
  VIEW_USER_LIST: 'view_user_list',
  VIEW_ROLE_LIST: 'view_role_list',
  VIEW_LOG_LIST: 'view_log_list',
  VIEW_DATA_LIST_LIST: 'view_data_list_list',
  VIEW_FINANCIALS_LIST: 'view_financials_list',
  // Translations
  VIEW_TRANSLATION_LIST: 'view_translations_list',

  // Templates
  VIEW_TEMPLATES_LIST: 'view_templates_list'
};

export const FULL_PERMISSIONS = Object.values(PERMISSIONS);
